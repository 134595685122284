<template>
  <div>
    <div class="bg_box"></div>
    <div class="centerbox">
      <headbox
        title="相关协议"
        bgColor="#39394D"
        isborder="true"
        :isbackPath="true"
        path="login_out" />
      <div class="flex-between  bgwhite" @click="goPriceAgreements">
        <div>定价协议</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="flex-between  bgwhite" @click="goRegisterAgreements">
        <div>注册协议</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>

<script>

import headbox from "@/components/head";

export default {
  components:{
    headbox
  },
  data() {
    return {
      data: '',
      loginout: ""
    }
  },
  methods: {
    onClickLeft(){
      this.$router.push({
        name: 'login_out'
      })
    },
    // 定价协议
    goPriceAgreements(){
      this.$router.push({ name: "AccountContract", query: {"loginout": true}});
    },
    // 注册协议
    goRegisterAgreements(){
      this.$router.push({ name: "RegisterAgreements" });
    },
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/index.sass";
::v-deep{
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      .van-nav-bar__title{
        font-size: 36px;
        color: #212121;
      }
      .van-icon{
        font-size: 40px;
        color: #212121;
        right: 4px;
      }
    }
  }

}

.bgwhite {
  font-size: 32px;
  padding: 35px 30px;
  background: #fff;
  margin-bottom: 20px;
  i {
    margin-top: 10px;
  }
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.centerbox {
  position: relative;
  z-index: 1;
}
</style>